import { Section } from "components/Section";
import styled from "styled-components";
import { StyledDiv } from "./StyledSx";

export const Background = styled(StyledDiv)`
  position: relative;
  padding-top: 100px;
  margin-bottom: 100px;
  ${Section} {
    color: #ffffff;
    a {
      color: #ffffff;
    }
    a:hover {
      color: #efefef;
    }
  }

  @media (max-width: 768px) {
    padding-top: 0;
  }

  &:before {
    display: block;
    content: '';
    position: absolute;
    transform: skew(0, -15deg);
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-image: linear-gradient(40deg, #12e0c2 0%, #aab4e9 40%);
  }
  &:after {
    display: block;
    position: absolute;
    content: '';
    z-index: -1;
    right: 0;
    left: 0;
    bottom: 0;
    height: 80%;
    background-image: linear-gradient(30deg, #12e0c2 0%, #aab4e9 40%);
  }
`;
