// i18next-extract-mark-ns-start pay-by-link

//Payment methods
import {AnchorLink} from 'components/AnchorLink';
import {Background} from 'components/Background';
import {Clients} from 'components/Clients';
import {ContactSalesButton} from 'components/ContactSalesButton';
import {Content} from 'components/Content';
import {Section, SectionActions, SectionHeader, SectionImage} from 'components/Section';
import {SEO} from 'components/SEO';
import {SignUpButton} from 'components/SignUpButton';
import {graphql, PageProps} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';
import index2 from 'images/best_payment_gateway_rates.svg';

//Images
import pay_by_link_EN from 'images/pay-by-link-feature.svg';
import pay_by_link_ES from 'images/pay-by-link-feature_es.svg';

// Clients
import index1 from 'images/payment_gateway.svg';
import index6 from 'images/payment_provider.svg';
import index3 from 'images/shopify_payment_gateway.svg';

import React from 'react';
import styled from 'styled-components';
import {IndexImage} from 'components/landings/IndexImage';
import {BlogLink} from 'components/links/Blog';
import {InternalPageLink} from 'components/links/Pages';
import {GoogleReviews, Place} from 'components/GoogleReviews';

const IndexBackground = styled.div`
  position: relative;

  &:before {
    display: block;
    content: '';
    position: absolute;
    transform: rotate(-58deg);
    left: 70%;
    top: 0;
    height: 770px;
    width: 1150px;
    z-index: -1;
    background: #aab4e9;
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

const IndexContent = styled.div`
  padding-top: 30px;
  max-width: 500px;
  @media (max-width: 768px) {
    padding-top: 10px;
  }
`;

const ReviewsBackground = styled.div`
  position: relative;
  margin-top: 100px;

  &:before {
    display: block;
    content: '';
    z-index: -1;
    height: 70%;
    position: absolute;
    transform: skew(0, -9deg);
    left: 0;
    right: 0;
    bottom: 0;
    top: 20%;
    background: linear-gradient(96.34deg, #00e9d5 0%, #aab4e9 100%);
  }
`;

const PayByLink: React.FC<PageProps> = (props) => {
  const {t, language} = useI18next();
  const payByLink = language === 'es' ? pay_by_link_ES : pay_by_link_EN;
  const {data} = props;
  const place: Place = {
    rating: data.googlePlacesPlace.rating,
    user_ratings_total: data.googlePlacesPlace.user_ratings_total,
    reviews: data.allGooglePlacesReview.nodes
  };
  return (
    <>
      <SEO
        path="pay-by-link"
        title="Pay By Link"
        description={t(
          'Use Pay By Link to send your customers a link to pay online in one click! Works with or without a website. Send payment links via email or SMS. Start now >>'
        )}
      />
      <IndexBackground>
        <Content>
          <Section reverseOnMobile>
            <IndexContent>
              <SectionHeader underline tagName="h1">
                <Trans>Create Pay By Link requests with MONEI</Trans>
              </SectionHeader>
              <Trans parent="p">
                Send your customers a unique link to pay online in one click! Stop spending time on
                manual invoices and use MONEI's Pay By Link feature to create secure payment links
                hosted by us and set up by you. This new way to sell allows you to process online
                transactions with or without a website. All you need is a social media account to
                configure Pay By Link.
              </Trans>
              <SectionActions>
                <SignUpButton variant="light" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
                <ContactSalesButton />
              </SectionActions>
            </IndexContent>
            <IndexImage
              width="570px"
              left="45%"
              top="60px"
              src={payByLink}
              alt="Pay By Link feature screenshot"
              className="hide-on-mobile"
            />
          </Section>
        </Content>
      </IndexBackground>
      <Background>
        <Content>
          <Section>
            <SectionImage
              src={index2}
              alt="MONEI Payment Gateway"
              title="MONEI Payment Gateway"
              width={600}
              height={350}
              mobileWidth={280}
            />
            <div>
              <SectionHeader>
                <Trans>Send a payment link via any channel</Trans>
              </SectionHeader>
              <Trans parent="p">
                Pay By Link gives you total flexibility over how and when you request payments from
                your customers. Create your payment page and then send the{' '}
                <BlogLink slug="email-payment-link">payment link</BlogLink> via your preferred
                channel including email, SMS, social media, and WhatsApp.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Discover MONEI</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
          <Section reverseOnMobile>
            <div>
              <SectionHeader>
                <Trans>Create your payment links in a secure environment</Trans>
              </SectionHeader>
              <Trans parent="p">
                Leave payment security up to us. We host your Pay By Link page and safely process
                transactions in compliance with{' '}
                <BlogLink slug="what-is-3d-secure-and-its-advantages-for-e-commerce">
                  3D Secure
                </BlogLink>{' '}
                and <BlogLink slug="understanding-pci-compliance">Level 1 PCI DSS</BlogLink>{' '}
                regulations. After you send a Pay By Link request, track the payment status in
                real-time from your MONEI dashboard. You’ll receive an automated notification once
                the customer has paid.
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Get MONEI Now</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
            <SectionImage
              src={index3}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={400}
              height={428}
              mobileWidth={180}
            />
          </Section>
          <Section>
            <SectionImage
              src={index1}
              alt="Secure payment gateway"
              title="Secure payment gateway"
              width={600}
              height={600}
              mobileWidth={280}
            />
            <div>
              <SectionHeader>
                <Trans>Accept a wide range of payment methods</Trans>
              </SectionHeader>
              <Trans parent="p">
                Accepting more payment methods leads to improved customer satisfaction and more
                sales. That’s why we aggregate{' '}
                <InternalPageLink slug="payment-methods">
                  all major and alternative payment methods
                </InternalPageLink>{' '}
                in a single platform. We aim to help you reach more customers and grow your business
                faster.
              </Trans>
              <Trans parent="p">
                Use Pay By Link to{' '}
                <InternalPageLink slug="payment-methods/credit-cards">
                  accept credit cards
                </InternalPageLink>
                , digital wallets including{' '}
                <InternalPageLink slug="payment-methods/google-pay">Google Pay</InternalPageLink>,{' '}
                <InternalPageLink slug="payment-methods/apple-pay">Apple Pay</InternalPageLink>, and{' '}
                <InternalPageLink slug="payment-methods/paypal">PayPal</InternalPageLink>, or local
                payment methods such as{' '}
                <InternalPageLink slug="payment-methods/bizum">Bizum</InternalPageLink> in Spain.
              </Trans>
              <Trans parent="p">
                Learn how to configure payment methods in your MONEI account{' '}
                <AnchorLink href="https://support.monei.com/hc/en-us/categories/360003378957-Payment-methods">
                  here
                </AnchorLink>
                .
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
          </Section>
          <Section reverseOnMobile>
            <div>
              <SectionHeader>
                <Trans>Easily create your customized payment page</Trans>
              </SectionHeader>
              <Trans parent="p">
                Quickly and easily create a payment page from your MONEI dashboard. Add your logo
                and brand colors to customize the appearance and build brand awareness. If you
                already have a website, you can also add your custom domain to the payment link.
              </Trans>
              <Trans parent="p">
                Use Pay By Link to give your customers a seamless payment process. You'll pre-fill a
                few fields including customer contact information, order details, and the payment
                amount. Then copy and paste the URL to send your Pay By Link request to the customer
                via your chosen channel.
              </Trans>
              <Trans parent="p">
                Don't have a website?{' '}
                <AnchorLink href="https://dashboard.monei.com/?action=signUp">
                  Register with MONEI
                </AnchorLink>{' '}
                using your social media URL!
              </Trans>
              <SectionActions>
                <SignUpButton variant="dark" style={{marginRight: 15}}>
                  <Trans>Open an Account</Trans>
                </SignUpButton>
              </SectionActions>
            </div>
            <SectionImage
              src={index6}
              alt="Payment Gateway for Developers"
              title="Payment Gateway for Developers"
              width={392}
              height={358}
            />
          </Section>
        </Content>
      </Background>
      <ReviewsBackground>
        <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
          <SectionHeader underline sx={{textAlign: 'center'}}>
            <Trans>What MONEI merchants are saying</Trans>
          </SectionHeader>
        </div>
        <GoogleReviews place={place} />
      </ReviewsBackground>
      <Content>
        <Section textAlign="center">
          <div>
            <SectionHeader underline tagName="h3">
              <Trans>Proudly used by more than 5000 stores</Trans>
            </SectionHeader>
            <Clients />
          </div>
        </Section>
      </Content>
    </>
  );
};

export default PayByLink;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "pay-by-link"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    googlePlacesPlace {
      user_ratings_total
      rating
    }
    allGooglePlacesReview(sort: {fields: rating, order: DESC}, filter: {rating: {gt: 2}}) {
      nodes {
        id
        rating
        text
        relative_time_description
        profile_photo_url
        author_name
      }
    }
  }
`;
