import styled from "styled-components";
import React from "react";
import { ScreenSizes } from "../../../types/responsive";
import { StyledImg } from "../StyledSx";

type Props = React.ImgHTMLAttributes<HTMLImageElement> & {
  left?: string;
  top?: string;
  width?: string;
  hideLtMd?: boolean;
};
export const IndexImage = styled(StyledImg)<Props>`
  position: absolute;
  left: ${(props) => props.left || '52%'};
  top: ${(props) => props.top || '100px'};
  width: ${(props) => props.width || '600px'};
  z-index: 10;
  filter: drop-shadow(0 0 0.75rem rgba(0, 0, 0, 0.2));

  @media (max-width: ${ScreenSizes.lg}) {
    transform: translateY(-30px);
  }
  @media (max-width: ${ScreenSizes.md}) {
    ${(props) => props.hideLtMd && 'display: none'};
  }

  @media (max-width: ${ScreenSizes.sm}) {
    position: initial;
  }
`;
